import React, { lazy, useEffect, useState } from 'react'
import './App.css';
import initializeApp from './app/init';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { httpsCallable } from '@firebase/functions';
import { useAuth } from './app/auth';
const LandingPage = lazy(() => import('./pages/LandingPage'))
const VideoRestyle = lazy(() => import('./pages/VideoRestyle'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const TermsOfuse = lazy(() => import('./pages/TermsOfUse'))
initializeApp()

function App() {  

  //eslint-disable-next-line
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user?.['displayName']) {
      setHighlightMetaData()
    }

    // eslint-disable-next-line
  }, [auth?.user]);

  async function setHighlightMetaData() {
    // console.log('Highlight Loaded');
    const getSubscriptionInfo = httpsCallable(auth?.functions, 'getSubscriptionInfo');
    const response = await getSubscriptionInfo();
    // console.log("highlights",response.data);
    setSubscriptionStatus(response.data);
    // //convert createdAt to 2021-09-01 format
    // const date = new Date(response.data['createdAt']);
    // const createdAt = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    // H.identify((auth?.user?.['screenName'] || auth?.user?.['displayName']), {
    //   firebaseId: auth?.user?.['localId'],
    //   status: response.data['status'],
    //   createdAt: createdAt
    // });
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/restyle/:id" element={<VideoRestyle />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/terms-of-use" element={<TermsOfuse />} />
        </Routes>
      </Router>
    </>
  );
}

export default App
