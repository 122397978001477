import { configureStore } from '@reduxjs/toolkit'
import modalSlice from '../components/common/modalSlice'
import subcriptionSlice from '../components/common/subcriptionSlice'
import headerSlice from '../components/common/headerSlice'

const combinedReducer = {
  header : headerSlice,
  modal : modalSlice,
  submodal:subcriptionSlice
}

export default configureStore({
    reducer: combinedReducer
})