import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        title: "",  // current  title state management
        isOpen : false,   // modal state management for opening closing
        uploadVideo : "",   // modal content management
        size : "",   // modal content management
        extraObject : {}, 
        openVideo : "",
        mangeSubcription : "",
        signIn : "",
        process : "",
        icon : {}, 
        managePlan: "" ,
        time: "",
        styleImageUrl: ""
        
    },
    reducers: {

        openModal: (state, action) => {
            const {title, bodyType, extraObject, size , uploadVideo, openVideo, mangeSubcription, upgradeSubcription, signIn, icon, process, managePlan, time, styleImageUrl} = action.payload
            state.isOpen = true
            state.bodyType = bodyType
            state.title = title
            state.size = size || 'md'
            state.extraObject = extraObject
            state.uploadVideo = uploadVideo
            state.openVideo = openVideo
            state.mangeSubcription = mangeSubcription
            state.upgradeSubcription = upgradeSubcription
            state.signIn = signIn
            state.icon = icon
            state.process = process
            state.managePlan = managePlan
            state.time = time
            state.styleImageUrl = styleImageUrl
          
        },

        closeModal: (state, action) => {
            state.isOpen = false
            state.bodyType = ""
            state.title = ""
            state.extraObject = {}
            state.uploadVideo = ""
            state.openVideo = ""
            state.mangeSubcription = ""
            state.upgradeSubcription = ""
            state.signIn = ""
            state.icon = ""
            state.process = ""
            state.managePlan = ""
            state.time = ""
            state.styleImageUrl = ""

        },

    }
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer