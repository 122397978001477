import { createSlice } from '@reduxjs/toolkit'

export const subcriptionSlice = createSlice({
    name: 'submodal',
    initialState: {
        isOpen : false,
        upgradeSubcription : "",
        size : "",
    },
    reducers: {
        openModal: (state, action) => {
            const {size,upgradeSubcription} = action.payload
            state.isOpen = true
            state.size = size || 'md'
            state.upgradeSubcription = upgradeSubcription
          
        },

        closeModal: (state, action) => {
            state.isOpen = false
            state.upgradeSubcription = ""

        },
    }
})

export const { openModal, closeModal } = subcriptionSlice.actions
export default subcriptionSlice.reducer